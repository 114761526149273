@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: "Work Sans", sans-serif !important;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.legendPercent {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
}
.Mui-selected {
    font-weight: 400 !important;
}

.MuiAutocomplete-root {
    margin: 0 auto;
    width: 100%;
    max-width: 13rem;
}

.leaflet-control-attribution {
    display: none;
}

fieldset {
    border-color: transparent !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
    margin: 0;
    max-width: 300px;
    width: 100%;
}

.MuiInputBase-input.MuiOutlinedInput-input:focus {
    border-radius: 0;
}

.MuiTextField-root
    > div.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1kne6kk-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 0;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "Noto Sans", sans-serif !important;
    color: #1e2dbe !important;
    font-size: 14px;
    font-weight: 400;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "Noto Sans", sans-serif !important;
    color: #1e2dbe !important;
    font-size: 14px;
    font-weight: 400;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: "Noto Sans", sans-serif !important;
    color: #1e2dbe !important;
    font-size: 14px;
    font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

textarea:focus,
input:focus {
    outline: none;
}

/* Scrollbar */
.scrollable::-webkit-scrollbar {
    width: 0.65rem;
}

.scrollable::-webkit-scrollbar-track {
    background: #daf3fe;
    margin: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #222222;
    border: 0.18rem solid #daf3fe;
    border-radius: 0.5rem;
}

/* Select */
.MuiInputBase-input {
    font-weight: bold !important;
    color: black !important;
}

.MuiSelect-select {
    padding: 0 !important;
}
